import { d as display } from '../common/display-02d955d1.js';
import '../common/_commonjsHelpers-8c19dec8.js';
import '../common/settings-74edbb19.js';
import '../common/math-4b280615.js';
import '../common/utils-3a587c1c.js';
import '../common/index-f7276418.js';
import '../common/earcut-2ad6c5fa.js';
import '../common/url-a096fc32.js';
import '../common/punycode-03dd95bc.js';
import '../common/index-c9b49f27.js';
import '../common/constants-614db509.js';

/**
 * The instance name of the object.
 * @memberof PIXI.DisplayObject#
 * @member {string} name
 */
display.DisplayObject.prototype.name = null;
/**
 * Returns the display object in the container.
 *
 * Recursive searches are done in a preorder traversal.
 * @method getChildByName
 * @memberof PIXI.Container#
 * @param {string} name - Instance name.
 * @param {boolean}[deep=false] - Whether to search recursively
 * @returns {PIXI.DisplayObject} The child with the specified name.
 */
display.Container.prototype.getChildByName = function getChildByName(name, deep) {
    for (var i = 0, j = this.children.length; i < j; i++) {
        if (this.children[i].name === name) {
            return this.children[i];
        }
    }
    if (deep) {
        for (var i = 0, j = this.children.length; i < j; i++) {
            var child = this.children[i];
            if (!child.getChildByName) {
                continue;
            }
            var target = this.children[i].getChildByName(name, true);
            if (target) {
                return target;
            }
        }
    }
    return null;
};

var mixinGetChildByName = {

};

export default mixinGetChildByName;
