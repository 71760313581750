import { d as display } from '../common/display-02d955d1.js';
import { m as math } from '../common/math-4b280615.js';
import '../common/_commonjsHelpers-8c19dec8.js';
import '../common/settings-74edbb19.js';
import '../common/utils-3a587c1c.js';
import '../common/index-f7276418.js';
import '../common/earcut-2ad6c5fa.js';
import '../common/url-a096fc32.js';
import '../common/punycode-03dd95bc.js';
import '../common/index-c9b49f27.js';
import '../common/constants-614db509.js';

/**
 * Returns the global position of the displayObject. Does not depend on object scale, rotation and pivot.
 * @method getGlobalPosition
 * @memberof PIXI.DisplayObject#
 * @param {PIXI.Point} [point=new PIXI.Point()] - The point to write the global value to.
 * @param {boolean} [skipUpdate=false] - Setting to true will stop the transforms of the scene graph from
 *  being updated. This means the calculation returned MAY be out of date BUT will give you a
 *  nice performance boost.
 * @returns {PIXI.Point} The updated point.
 */
display.DisplayObject.prototype.getGlobalPosition = function getGlobalPosition(point, skipUpdate) {
    if (point === void 0) { point = new math.Point(); }
    if (skipUpdate === void 0) { skipUpdate = false; }
    if (this.parent) {
        this.parent.toGlobal(this.position, point, skipUpdate);
    }
    else {
        point.x = this.position.x;
        point.y = this.position.y;
    }
    return point;
};

var mixinGetGlobalPosition = {

};

export default mixinGetGlobalPosition;
