import * as __SNOWPACK_ENV__ from './_snowpack/env.js';

import * as PIXI from './_snowpack/pkg/pixijs.js';
import { sound } from './_snowpack/pkg/@pixi/sound.js';
import gsap from './_snowpack/pkg/gsap.js';
import Hammer from './_snowpack/pkg/hammerjs.js';
import debounce from './_snowpack/pkg/lodash.debounce.js';
import {DropShadowFilter} from './_snowpack/pkg/@pixi/filter-drop-shadow.js';
import { getScreenSize } from './src/utils/dimensions.js';
import { Menu } from './src/Menu.js';
import { EntryViewer } from './src/EntryViewer.js';
import { ENTRIES, STICKERS } from './src/data/sections.js';

const RESOLUTION = window.devicePixelRatio ?  window.devicePixelRatio : 1;
const BASEURL = __SNOWPACK_ENV__.SNOWPACK_PUBLIC_PROD_ENV === "WP" ? "/wp-content/uploads/anuario2022-assets/" : "./assets/"

const STICKEROPTIONS = [
	"sdg-synergies",
	"datarepublica",
	"inec",
	"cepei",
	"transparenciaclimatica"
]

const menu  = new Menu( "#menu" ).setEntries( ENTRIES, BASEURL );
const viewer = new EntryViewer( "#content" )
const footer = {
	hovered: false,
	audio: true
}
const SIZE = 5000;

const container = document.getElementById( "container" );
container.style.height = getScreenSize()[ 1 ] + "px";
container.style.width = getScreenSize()[ 0 ] + "px";

const swipeManager = new Hammer( container )
swipeManager.get('swipe').set({ direction: Hammer.DIRECTION_ALL } );
swipeManager.get('pan' ).set({ direction: Hammer.DIRECTION_ALL, pointers: 1 } );

const bounds = [0, SIZE, 0, SIZE]
const SPEED = 24

// KEEP TRACK OF POSITION
const appPosition = [0, 0];
let mousePosition = {x: 0, y: 0}
let deltaPosition = {x: 0, y: 0}
let swipePosition = {x: 0, y: 0}
let panPosition = {x: 0, y: 0}

const PIXIOPTIONS = {
	width: window.innerWidth/RESOLUTION,
    height: window.innerHeight/RESOLUTION,
    resizeTo: document.querySelector( "body" ),
	backgroundAlpha: 0,
	antialias: true,
	resolution: RESOLUTION,
	autoDensity: true
	//forceCanvas: true
}

function appVw( multiplier ){
	var m = multiplier || 1
	return Math.round( app.screen.width/100 * m );
}

const app = new PIXI.Application( PIXIOPTIONS );
container.appendChild( app.view );

const Background = new PIXI.Container();
Background.x = 0;
Background.y = 0;
Background.width = SIZE;
Background.height = SIZE;
app.stage.addChild( Background );

const logo = new PIXI.Container();
logo.x = app.screen.width / 2;
logo.y = app.screen.height / 2;
logo.pivot.set( app.screen.width / 2, app.screen.height / 2 )
app.stage.addChild( logo );

const Objects = new PIXI.Container();
Objects.x = 0;
Objects.y = 0;
app.stage.addChild( Objects );

// SET SHARED TICKER FOR ANIMATIONS
let ticker = PIXI.Ticker.shared;
ticker.autoStart = false;
ticker.stop();

const PreLoader = new PIXI.Loader()

const Backgrounds = {}
Background.interactive = true;

function createLogo( texture ){
	//var title = new PIXI.Container()
	var title = new PIXI.Sprite( texture )
	title.pivot.set( 0.5 )
	title.anchor.set( 0.5 )
	title.x = app.screen.width / 2;
	title.y = app.screen.height / 2;

	title.width = Math.min( appVw( 85 ), 800 );
	title.height = Math.round( 0.1512 * title.width )
	logo.addChild( title );
}

function createEntry( entryData, texture ){
	var size = Math.min( appVw( 60 ), 420 );
	var entry = new PIXI.Container();

	if( app.screen.width < 900 && entryData.mobilePosition ){
		entry.x = entryData.mobilePosition[ 0 ]
		entry.y = entryData.mobilePosition[ 1 ]	
	} else {
		entry.x = entryData.position[ 0 ]
		entry.y = entryData.position[ 1 ]	
	}

	entry.width = size;
	entry.height = size;
	entry.pivot.set( size/2, size/2)
	entry.interactive = true;
	entry.rotation = Math.random() * Math.PI*0.3 - 0.15*Math.PI
	entry.title = entryData.label;
	entry.buttonMode = true;
	entry.defaultCursor = "pointer"

	const shadow = new DropShadowFilter( {quality: 5, distance: 8});
	const border = new PIXI.Graphics();
	border.lineStyle( 8, 0xFFFFFF );
	border.drawRect( 0, 0, size, size );
	
	var bg = new PIXI.Sprite( texture );
	bg.width = size;
	bg.height = size;
	bg.filters = [ shadow ]
	entry.addChild( bg )
	entry.addChild( border );
	return entry
}

function createSticker( stickerData, texture ){
	var size = Math.min( appVw( 30 ), 200 )
	var entry = new PIXI.Container();
	entry.x = stickerData.position[ 0 ]
	entry.y = stickerData.position[ 1 ]
	entry.width = size;
	entry.height = size;
	entry.pivot.set( size/2, size/2 );
	entry.interactive = true;
	entry.buttonMode = true;
	entry.defaultCursor = "pointer"

	var bg = new PIXI.Sprite( texture );
	bg.width = size;
	bg.height = size;
	entry.addChild( bg )
	return entry;
}


PreLoader.add( 'bg', BASEURL + 'backgrounds/bg-pattern.png' )
	.add( 'title', BASEURL + 'logos/titulo-anuario-center.png')
	.add( 'title_mob', BASEURL + 'logos/titulo-anuario-center.png')
	.add( 'sticker_a', BASEURL +'stickers/a.png')
	.add( 'sticker_b', BASEURL + 'stickers/b.png')
	.add( 'sticker_c', BASEURL + 'stickers/c.png')
	.add( 'sticker_d', BASEURL + 'stickers/d.png');


ENTRIES.forEach( e => {
	PreLoader.add( e.img, BASEURL + e.imgURL );
})

PreLoader.add( 'music', BASEURL + 'audio/test-theme.mp3' );
PreLoader.add( 'sticker', BASEURL + 'audio/sticker.mp3' );
PreLoader.add( 'cover', BASEURL + 'audio/cover.mp3');

let pointerContext = {};

PreLoader.load( ( loader, resources ) => {
	Backgrounds.bg = new PIXI.TilingSprite( resources.bg.texture, (SIZE + getScreenSize()[0])/2 - 200, (SIZE + getScreenSize()[1])/2 - 200)
	Backgrounds.bg.x = 100;
	Backgrounds.bg.y = 100;

	Background.addChild( Backgrounds.bg )

	createLogo( app.screen.width < 900 ? resources.title_mob.texture : resources.title.texture );

	ENTRIES.forEach( function( entry ){
		var drawnEntry = createEntry( entry, app.screen.width < 900 && resources[ entry.img + "_mob" ] ? resources[entry.img + "_mob"].texture : resources[entry.img].texture )

		Objects.addChild( drawnEntry );

		drawnEntry.on( "mouseover", function( evt ){
			var target = evt.currentTarget;
			gsap.to(target, {rotation: 0, duration: 0.5 } )
			gsap.to(target.scale, {x: 1.2, y: 1.2})

			if( footer.audio ){
				sound.play( 'cover' )
			}
		} )

		drawnEntry.on( "mouseout", function( evt ){
			var target = evt.currentTarget;
			gsap.to(target, {rotation: Math.random() * Math.PI * 0.3 - 0.15 * Math.PI, duration: 0.5 } )
			gsap.to(target.scale, {x: 1, y: 1})
		})

		drawnEntry.on( "click", function (evt ){
			var target = evt.currentTarget;
			viewer.setSelection( entry );
		} )

		drawnEntry.on( "pointerdown", function (evt ){
			pointerContext = {
				target: evt.currentTarget,
				start: Date.now()
			}
		} )
		drawnEntry.on( "pointerup", function( evt ){
			if( !panPosition.panning && pointerContext.target && evt.currentTarget === pointerContext.target && Date.now() - pointerContext.start  < 100 ){
				var target = evt.currentTarget;

				gsap.to(target, {rotation: 0, duration: 0.5 } )
				gsap.to(target.scale, {x: 1.2, y: 1.2})
				if( footer.audio ){
					sound.play( 'cover' )

				}

				setTimeout( () => {
					gsap.to(target, {rotation: Math.random() * Math.PI * 0.3 - 0.15 * Math.PI, duration: 0.5 } )
					gsap.to(target.scale, {x: 1, y: 1})
					viewer.setSelection( entry );
				}, 500 )
			
				pointerContext = {}
			}
		} )
	} )

	STICKERS.forEach( function( sticker ){
		var drawnSticker = createSticker( sticker, resources[ sticker.img ].texture )

		Objects.addChild( drawnSticker );

		drawnSticker.on( "mouseover", function( evt ){
			var target = evt.currentTarget;
			gsap.to(target, {rotation: Math.PI * 8, duration: 0.5 } )
			gsap.to(target.scale, {x: 1.2, y: 1.2})

			if( footer.audio ){
				sound.play( 'sticker' )
			}
		} )

		drawnSticker.on( "mouseout", function( evt ){
			var target = evt.currentTarget;
			gsap.to(target, {rotation: 0, duration: 0.5 } )
			gsap.to(target.scale, {x: 1, y: 1})
		})

		drawnSticker.on( "click", function (evt ){
			var target = evt.currentTarget;
			sticker.link = "https://showandtelldata.com/project/" + STICKEROPTIONS[ Math.floor( Math.random() * STICKEROPTIONS.length ) ];
			viewer.setSelection( sticker );
		} )

		drawnSticker.on( "pointerdown", function (evt ){
			pointerContext = {
				target: evt.currentTarget,
				start: Date.now()
			}
		} )
		drawnSticker.on( "pointerup", function( evt ){
			if( !panPosition.panning && pointerContext.target && evt.currentTarget === pointerContext.target && Date.now() - pointerContext.start  < 300 ){
				var target = evt.currentTarget;
				gsap.to(target, {rotation: Math.PI * 8, duration: 0.5 } )
				gsap.to(target.scale, {x: 1.2, y: 1.2})

				if( footer.audio ){
					sound.play( 'sticker' )
				}

				setTimeout( () => {
					sticker.link = sticker.link = "https://showandtelldata.com/project/" + STICKEROPTIONS[ Math.floor( Math.random() * STICKEROPTIONS.length ) ];
					viewer.setSelection( sticker );
					gsap.to(target, {rotation: 0, duration: 0.5 } )
					gsap.to(target.scale, {x: 1, y: 1})
				}, 500)
				
			}
			pointerContext = {}
		} )
	} )
	document.querySelector( ".loading-indicator" ).style.display = "none"
	document.querySelector( ".button-container" ).style.display = "block"
	document.querySelector( "#start" ).addEventListener("click", init )
} )

function init(){
	if( window.orientation === 90 || window.orientation === -90 ){
		return;
	}
	document.querySelector( "#loading-container" ).style.display = "none";

	setTimeout( ticker.start.bind( ticker ), 1000 );

	// Initiate in the center
	updateBoardPosition( Background, -1 * (SIZE/2 - app.screen.width/2), -1 * ( SIZE/2 - app.screen.height/2))

	ticker.add( handleNavigation )
	ticker.add( startRandomMotion, randomMotionContext )

	menu.on( "selection", function( entry ){
		if( app.screen.width < 900 && entry.mobilePosition ){
			var xChange = appPosition[ 0 ] - (entry.mobilePosition[ 0 ] - app.screen.width/2);
			var yChange = appPosition[ 1 ] - (entry.mobilePosition[ 1 ] - app.screen.height/2);	
		} else {
			var xChange = appPosition[ 0 ] - (entry.position[ 0 ] - app.screen.width/2);
			var yChange = appPosition[ 1 ] - (entry.position[ 1 ] - app.screen.height/2);	
		}
		
		updateBoardPosition( Background, xChange, yChange )

		menu.close();
		viewer.setSelection( entry );
	})

	mousePosition = {x: app.screen.width/2, y: app.screen.height/2}
	
	sound.play( 'music', {loop: true, volume: 0.5} )

	// Handle resize event
	window.onresize = handleResize

	window.addEventListener( "orientationchange", handleRotation);
}

const randomMotionContext = {
	elapsed: 0
}

function handleNavigation( time ){
	if( menu.isActive || menu.isHovered || viewer.isViewing || footer.hovered ){
		return;
	}
	var changeX = 0;
	var changeY = 0;
	var xLimit = app.screen.width * 0.4
	var yLimit = app.screen.height * 0.4
 	// React to mouse position changes
	if( mousePosition.x <  xLimit ){
		var rate = ( xLimit - mousePosition.x ) / xLimit
		changeX = rate * SPEED;
	}
	if( mousePosition.y < yLimit ){
		var rate = ( yLimit - mousePosition.y ) / yLimit
		changeY = rate * SPEED;
	}
	if( mousePosition.y > app.screen.height - yLimit ){
		var rate = ( mousePosition.y - app.screen.height + yLimit ) / yLimit
		changeY = rate * -SPEED;
	}
	if( mousePosition.x > app.screen.width - xLimit ){
		var rate = ( mousePosition.x - app.screen.width + xLimit ) / xLimit;
		changeX = rate * -SPEED;
	}

	// React to arrow buttons changes
	if( deltaPosition.x !== 0 ){
		if( deltaPosition.x < 0 ){
			changeX = SPEED
		} else {
			changeX = -SPEED
		}
	}
	if( deltaPosition.y !== 0 ){
		if( deltaPosition.y < 0 ){
			changeY = SPEED
		} else {
			changeY = -SPEED
		}
	}

	// React to swipe movement 
	if( swipePosition.x !== 0 || swipePosition.y !== 0 ){
		swipePosition.elapsed += time
	}

	var t = Math.max( 60 - swipePosition.elapsed, 0.1 ) / 60 ;

	if( t <= 0.1 ){
		t = 0
	}

	if( swipePosition.x !== 0 ){
		if( swipePosition.x < 0 ){
			changeX = 2 * t * (1 - t) * swipePosition.x * swipePosition.speed;
			if( swipePosition.x - changeX/3 >= 0 ){
				swipePosition.x = 0
			} else {
				swipePosition.x -= changeX/3
			}
		}
		if( swipePosition.x > 0 ){
			changeX = 2 * t * (1 - t) * swipePosition.x * swipePosition.speed;
			if( swipePosition.x - changeX/3 <= 0 ){
				swipePosition.x = 0
			} else {
				swipePosition.x -= changeX/3
			}
		}
		var xRate = changeX/200

		if( swipePosition.y === 0 && swipePosition.x === 0){
			swipePosition.elapsed = 0
			xRate = 0
		}
		
	}
	if( swipePosition.y !== 0 ){
		if( swipePosition.y < 0 ){
			changeY = 2 * t * (1 - t) * swipePosition.y * swipePosition.speed;
			if( swipePosition.y - changeY/3 >= 0 ){
				swipePosition.y = 0;
			} else {
				swipePosition.y -= changeY/3
			}
		} 
		if( swipePosition.y > 0 ){
			changeY = 2 * t * (1 - t) * swipePosition.y * swipePosition.speed;
			if( swipePosition.y - changeY/3 <= 0 ){
				swipePosition.y = 0;
			} else {
				swipePosition.y -= changeY/3
			}
		}
		var yRate = changeY/200
		if( swipePosition.y === 0 && swipePosition.x === 0){
			swipePosition.elapsed = 0
			yRate = 0;
		}
	}

	if( panPosition.x !== 0 || panPosition.y !== 0 ){
		updateBoardPosition( Background, panPosition.x, panPosition.y );
		logoMovement( [panPosition.x, panPosition.y], panPosition.speed)
		panPosition.x = 0; 
		panPosition.y = 0;
	}

	if( changeX !== 0 || changeY !== 0 ){
		updateBoardPosition( Background, changeX, changeY );
	}

	var rateArray = [];

	if( xRate ){
		rateArray.push( xRate )
	} else {
		rateArray.push( rate );
	}

	if( yRate ){
		rateArray.push( yRate )
	} else {
		rateArray.push( rate )
	}

	logoMovement( [ changeX, changeY ], rateArray )
}

function logoMovement( change, rate ){
	if( change[0] < 0 ){
		gsap.to( logo, {rotation: ( Array.isArray( rate ) ? rate[ 0 ] : rate ) * Math.PI/8, duration: 0.4} );
	}
	if( change[0] > 0 ){
		gsap.to( logo, {rotation: ( Array.isArray( rate ) ? -1 * rate[ 0 ] : -1 * rate ) * Math.PI/8, duration: 0.4} );
	}
	if( change[0] === 0 ){
		gsap.to( logo, { rotation: 0, duration: 0.4 } )
	}
	if( change[1] < 0 ){
		gsap.to(logo, {y: app.screen.height/2 - (Array.isArray( rate ) ? -1 * rate[ 1 ] : rate) * 50, duration: 0.4} );
	}
	if( change[1] > 0 ){
		gsap.to(logo, {y: app.screen.height/2 + (Array.isArray( rate ) ? rate[ 1 ] : rate) * 50, duration: 0.4} )
	}
	if( change[1] === 0 ){
		gsap.to(logo, {y: app.screen.height/2, duration: 0.4} )
	}
}

function startRandomMotion( time ){
	this.elapsed += time/50
	floating( this.elapsed )
}

function floating( elapsed ){
	Objects.children.forEach( function( child, i ){
		child.y = child.y + Math.sin( elapsed + i )/6; 
	} )
}

function checkMinXBound( change ){
	return appPosition[ 0 ] + change >= bounds[ 0 ];
}
function checkMaxXBound( change ){
	return appPosition[ 0 ] + change <= bounds[ 1 ] - getScreenSize()[ 0 ];
}
function checkMinYBound( change ){
	return appPosition[ 1 ] + change >= bounds[ 2 ];
}
function checkMaxYBound( change ){
	return appPosition[ 1 ] + change <= bounds[ 3 ] - getScreenSize()[ 1 ];
}

function checkXBounds( change ){
	return checkMinXBound( change ) && checkMaxXBound( change );
}
function checkYBounds( change ){
	return checkMinYBound( change ) && checkMaxYBound( change );
}
function updateBoardPosition( group, changeX, changeY ){
	var n = group.children.length;

	var deltaX;
	var deltaY;

	// Bounds check
	if( checkXBounds( -1 * changeX ) || changeX === 0 ){
		deltaX = changeX;
	} else {
		if( !checkMinXBound( -1 * changeX ) ){
			if( appPosition[ 0 ] === 0 ){
				deltaX = 0;
			} else {
				deltaX = appPosition[ 0 ];	
			}
		} else if( !checkMaxXBound( -1 *changeX ) ){
			if( Math.abs( changeX ) <= SPEED || appPosition[ 0 ] >= SIZE - getScreenSize()[ 0 ] ){
				deltaX = 0;
			} else {
				deltaX = appPosition[ 0 ] - SIZE + getScreenSize()[ 0 ];
			}
		} else {
			deltaX = 0
		}
	}

	if( checkYBounds( -1 * changeY ) || changeY === 0){
		deltaY = changeY;
	} else {
		if( !checkMinYBound( -1 * changeY ) ){
			if( appPosition[ 1 ] === 0 ){
				deltaY = 0;
			} else {
				deltaY = appPosition[ 1 ];	
			}
		} else if( !checkMaxYBound( -1 *changeY ) ) {
			if( Math.abs( changeY ) <= SPEED || appPosition[ 1 ] >= SIZE - getScreenSize()[ 1 ] ){
				deltaY = 0
			} else {
				deltaY =  appPosition[ 1 ] - SIZE + getScreenSize()[ 1 ];	
			}
		} else {
			deltaY = 0;
		}	
	}

	// UPDATE Xs
	if( deltaX !== 0 ){
		for( var i = n-1; i>=0; i--){
			var child = group.children[ i ];
			var d = 1 - ( n - i )/( n + 1 );
			child.x = child.x + deltaX * d;
		}

		Objects.x = Objects.x + deltaX;

		appPosition[ 0 ] += -1*deltaX;	
	}

	// UPDATE Ys
	if( deltaY !== 0 ){
		for( var i = n-1; i>=0; i--){
			var child = group.children[ i ];
			var d = 1 - ( n - i )/( n + 1 );
			child.y = child.y + deltaY * d;
		}

		Objects.y = Objects.y + deltaY;

		appPosition[ 1 ] += -1*deltaY;	
	}
}

if( Modernizr.touchevents ){
	/*swipeManager.on( "swipe", evt => {
		swipePosition.elapsed = 0;
		swipePosition.speed = Math.abs( +evt.overallVelocity )
		swipePosition.x = +evt.deltaX
		swipePosition.y = +evt.deltaY
	} )*/
	swipeManager.on( "panstart", evt => {
		panPosition.startX = evt.center.x;
		panPosition.startY = evt.center.y;
		panPosition.panning = true;
	})

	swipeManager.on( "panmove", evt => {
		panPosition.elapsed = 0;
		panPosition.speed = Math.abs( +evt.overallVelocity );

		panPosition.x = ( evt.center.x - panPosition.startX ) * Math.max( panPosition.speed, 2.5);
		panPosition.y = ( evt.center.y - panPosition.startY ) * Math.max( panPosition.speed, 2.5);

		panPosition.startX = evt.center.x;
		panPosition.startY = evt.center.y;
	} )

	swipeManager.on( "panend", evt => {
		panPosition.panning = false;
	} )
} else {
	Background.on( "pointermove", function ( evt ){
		mousePosition = {x: evt.data.global.x, y: evt.data.global.y }
	} )	
}

document.addEventListener( "keydown", function( evt ){
	if( menu.isActive || menu.isHovered || viewer.isViewing){
		return;
	}
	switch( evt.keyCode ){
		case 37:
			// Left
			deltaPosition.x = -1
			break;
		case 38:
			// Up
			deltaPosition.y = -1
			break;
		case 39:
			// Right
			deltaPosition.x = 1
			break;
		case 40:
			// Down
			deltaPosition.y = 1
			break;
	}
})

document.addEventListener( "keyup", function( evt ){
	if( menu.isActive || menu.isHovered || viewer.isViewing ){
		return;
	}
	switch( evt.keyCode ){
		case 37:
			// Left
			deltaPosition.x = 0
			break;
		case 38:
			// Up
			deltaPosition.y = 0
			break;
		case 39:
			// Right
			deltaPosition.x = 0
			break;
		case 40:
			// Down
			deltaPosition.y = 0
			break;
	}
})


document.querySelector( ".action-container" ).addEventListener( "mouseover", () => {
	footer.hovered = true;
} )
document.querySelector( ".logo-container" ).addEventListener( "mouseover", () => {
	footer.hovered = true;
} )

document.querySelector( ".action-container" ).addEventListener( "mouseout", () => {
	footer.hovered = false;
} )
document.querySelector( ".logo-container" ).addEventListener( "mouseout", () => {
	footer.hovered = false;
} )

document.querySelector( ".full-screen" ).addEventListener( "click", () => {
	document.body.requestFullscreen();
} )

document.querySelectorAll( ".audio.on" ).forEach( btn => {
	btn.addEventListener( "click", ( evt ) => {
		footer.audio = false;
		sound.pause( 'music' )
		evt.currentTarget.style.display = "none"
		btn.parentElement.querySelector( ".audio.off" ).style.display = "inline-block";
	} )
} )

document.querySelector( "#popup-container" ).addEventListener( "click", handlePopupClick );

document.querySelectorAll( ".audio.off" ).forEach( btn => {
	btn.addEventListener( "click", ( evt ) => {
		footer.audio = true;
		sound.resume( 'music', {loop: true} )
		evt.currentTarget.style.display = "none"
		btn.parentElement.querySelector( ".audio.on" ).style.display = "inline-block";
	} )
} )


function handleRotation(){
	if( window.orientation === 90 || window.orientation === -90 ){
		sound.pause( 'music' );
		document.querySelector( "#container" ).style.display = "none";
		document.querySelector( "#rotate-container" ).style.display = "flex";
		app.stop();
	} else {
		document.querySelector( "#rotate-container" ).style.display = "none";
		document.querySelector( "#container" ).style.display = "block";
		app.start();
		if( footer.audio ){
			sound.resume( 'music' )
		}
		setTimeout( () => {
			var newSize = getScreenSize();
			app.screen.width = newSize[0];
			app.screen.height = newSize[1];

			document.querySelector( "#container" ).style.height = newSize[ 1 ] + "px"
			
			updateBoardPosition( Background, -1 * (SIZE/2 - app.screen.width/2) + appPosition[ 0 ], -1 *( SIZE/2 - app.screen.height/2) + appPosition[ 1 ])
				
			Objects.children.forEach( c => {
				if( c.title ){
					var size = Math.min( appVw( 75 ), 420 );
					c.width = size;
					c.height = size;
					c.pivot.set( size/2, size/2)

					c.children.forEach( cc => {
						cc.width = size;
						cc.height = size;
					} )
				} else {
					var size = Math.min( appVw( 30 ), 200 )
					c.width = size;
					c.height = size;
					c.pivot.set( size/2, size/2 );

					c.children.forEach( cc => {
						cc.width = size;
						cc.height = size;
					} )
				}
			})
		}, 500)
	}
}

function handleResize(){
	var newSize = getScreenSize();
	container.style.width = newSize[ 0 ] + "px";
	container.style.height = newSize[ 1 ] + "px";

	var wDif = app.screen.width - newSize[ 0 ];
	var hDif = app.screen.height - newSize[ 1 ];

	app.screen.width = newSize[0];
	app.screen.height = newSize[1];

	// Reposition logo to center
	logo.x = app.screen.width / 2;
	logo.y = app.screen.height / 2

	// Adjust background size
	Backgrounds.bg.width = (SIZE + app.screen.width)/2 - 200
	Backgrounds.bg.height = (SIZE + app.screen.height)/2 - 200
	
	// Make sure that our position is still correct
	updateBoardPosition( Background, -1 * wDif, -1 * hDif )

	// Update logo size
	var logoSize = [9, 4]
	var maxLogoSize = [81, 36]
	logo.children[0].children.forEach( (c, i) => {
		c.style.fontSize =  Math.min( appVw( logoSize[ i ] ), maxLogoSize[ i ] );
	} )

	Objects.children.forEach( c => {
		if( c.title ){
			var size = Math.min( appVw( 75 ), 420 );
			c.width = size;
			c.height = size;
			c.pivot.set( size/2, size/2)

			c.children.forEach( cc => {
				cc.width = size;
				cc.height = size;
			} )
		} else {
			var size = Math.min( appVw( 30 ), 200 )
			c.width = size;
			c.height = size;
			c.pivot.set( size/2, size/2 );

			c.children.forEach( cc => {
				cc.width = size;
				cc.height = size;
			} )
		}
	})
}

function handlePopupClick( evt ){
	evt.currentTarget.style.display = "none";
}