import { sortByOrder } from './utils/data.js';
import { getScreenSize } from './utils/dimensions.js';
import { dispatch, on, off } from './utils/Dispatch.js';
import gsap from '../_snowpack/pkg/gsap.js';

function createMenuEntry( entry ){
	var li = document.createElement( "li" );
	li.classList.add( "menu-entry" );
	li.setAttribute( "data-section", entry.label );
	var html = `<span class="entry-number">${ String( entry.order + 1 ).padStart( 2, "0" ) }</span><span class="entry-label">${ entry.label.toUpperCase() }</span>`;
	li.innerHTML = html;
	return li;
}

function createEntryView( entry ){
	var wrapper = document.createElement( "div" );
	var rotation = 0 //getScreenSize()[0] >= 900 ? Math.random() * 40 - 20 : 0; 
	wrapper.classList.add( "menu-entry-view-wrapper" );
	wrapper.setAttribute( "data-section", entry.label )
	var html = `<div class="menu-entry-view-bg"
			style="transform: rotate( ${ rotation }deg);"
		>
			<img src="${ entry.imgCssURL }" />
		</div>`
	wrapper.innerHTML = html;
	return wrapper;
}

const getRootMargin = function(){
	var middle = getScreenSize()[0] >= 900 ? getScreenSize()[ 1 ] / 2 : getScreenSize()[ 1 ] * 0.4;
	var limit = middle - 16

	var indicator = document.createElement( "div" );
	var boxHeight = getScreenSize()[0] < 900 ? getScreenSize()[ 0 ]/100 * 7 : 0;
	var fullHeight = getScreenSize()[ 1 ];

	return `${ -1 * limit }px 0px ${ -1 * (limit - boxHeight)}px 0px`;
}

let menuObserverOptions = { threshold: getScreenSize()[0] >= 900 ? 0.05 : 0.7, rootMargin: getRootMargin() };

export function Menu( selector ){
	this.container = document.querySelector( selector );
	this.isActive = false;
	this.isHovered = false;
	this.selection = undefined;
	this.entries = [];
	this.listeners = {};
	this.dispatch = dispatch.bind( this );
	this.on = on.bind( this );
	this.off = off.bind( this );

	function setActive(){
		this.isActive = true;
	}
	function setInactive(){
		this.isActive = false;
	}

	function handleOpen( evt ){
		this.isActive = true;
		evt.currentTarget.style.display = "none";
		document.querySelector( "i.close" ).style.display = "block";
		this.container.classList.add( "active" );
	}

	function handleClose( evt ){
		setTimeout(setInactive.bind( this ), 500 );
		document.querySelector( "i.close" ).style.display = "none";
		document.querySelector( "i.open"  ).style.display = "block";
		this.container.classList.remove( "active" );
	}

	function handleMouseOver( evt ){
		this.isHovered = true;
	}
	function handleMouseOut( evt ){
		this.isHovered = false;
	}

	function handleEntryClick( evt ){
		evt.stopPropagation();
		var itemLabel = evt.currentTarget.getAttribute( "data-section" );
		if( this.selection === itemLabel ){
			return;
		}
		this.selection = itemLabel;

		var item = this.entries.find( function( entry ){
			return entry.label === itemLabel;
		} )

		if( item && item !== null ){
			this.dispatch( "selection", item )	
		}
	}

	function handleViewMouseOver( evt ){
		var square = evt.currentTarget.querySelector( ".menu-entry-view-bg" );
		gsap.to( square, {css: { rotation: 0, scale: 1.1 }, duration: 0.5 })
	}

	function handleViewMouseOut( evt ){
		var square = evt.currentTarget.querySelector( ".menu-entry-view-bg" );
		gsap.to( square, {css: { rotation: 0/*Math.random() * 40 - 20, scale: 1*/ }, duration: 0.5 })
	}

	function handleItemClick( evt ){
		const limit = getScreenSize()[ 0 ] / 2;

		if( evt.currentTarget.matches( "#item_view" ) ){
			var activeEntry = document.querySelector( ".menu-entry.hovered" );
			if( !activeEntry || activeEntry === null ){
				return;
			}
			var activeLabel = activeEntry.getAttribute( "data-section" );

			var item = this.entries.find( function( entry ){
				return entry.label === activeLabel
			} )

			if( item && item !== null){
				this.dispatch( "selection", item )
			}
			return
		}
		
		if( evt.clientX < limit ){
			var activeEntry = document.querySelector( ".menu-entry.hovered" );
			if( !activeEntry || activeEntry === null ){
				return;
			}
			var activeLabel = activeEntry.getAttribute( "data-section" );

			var item = this.entries.find( function( entry ){
				return entry.label === activeLabel
			} )

			if( item && item !== null){
				this.dispatch( "selection", item )
			}
		}
	}

	const handleIntersect = function( entries, observer ){
		entries.forEach( function( entry ){
			if( entry.isIntersecting ){
				entry.target.classList.add( "hovered" );
				var itemLabel = entry.target.getAttribute( "data-section" );
				var view = document.querySelector( `.menu-entry-view-wrapper[data-section='${ itemLabel }']`);
				if( view ){
					view.scrollIntoView( {behavior: "auto"} )
				}
			} else {
				entry.target.classList.remove( "hovered" )
			}
		} )
	}

	function handleViewMouseMove( evt ){
		if( getScreenSize()[ 0 ] < 900 ){
			return
		}

		const limit = getScreenSize()[ 0 ] / 2;
		if( evt.clientX < limit ){
			var activeEntry = document.querySelector( ".menu-entry.hovered" );
			if( !activeEntry || activeEntry === null ){
				return;
			}
			var activeLabel = activeEntry.getAttribute( "data-section" );

			var view = document.querySelector( `.menu-entry-view-wrapper[data-section='${ activeLabel }']` );
			if( view ){
				var square = view.querySelector( '.menu-entry-view-bg' )
				square.classList.add( "active" );
				gsap.to( square, {css: { rotation: 0, scale: 1.2 }, duration: 0.5 })
			}
		} else {
			document.querySelectorAll( `.menu-entry-view-bg.active` ).forEach( function( square ){
				square.classList.remove( "active" );
				gsap.to( square, {css: { rotation: 0/*Math.random() * 40 - 20*/, scale: 1 }, duration: 0.5 })
			} )
		}
	}

	let entryObserver = new IntersectionObserver( handleIntersect, menuObserverOptions );

	this.setEntries = function( entries, BASEURL ){
		if( Array.isArray( entries ) && entries.length ){
			this.entries = entries;

			var container = this.container.querySelector( "#menu_entries" );
			var viewer = this.container.querySelector( "#item_view" );
			// Add to menu
			this.entries.forEach( e => {
				e.imgCssURL = BASEURL + e.imgURL
			})

			this.entries.sort( sortByOrder )
				.map( createMenuEntry )
				.forEach( function( entry ){
					container.appendChild( entry );
				} )
			// Add to viewer
			this.entries.sort( sortByOrder )
				.map( createEntryView )
				.forEach( function( entry ){
					viewer.appendChild( entry );
				} );

			var self = this;
			this.container.querySelectorAll( ".menu-entry" ).forEach( function( entry ){
				entry.addEventListener( "click", handleEntryClick.bind( self ) )
				entryObserver.observe( entry );
			} )
		}

		return this;
	}

	this.close = function(){
		handleClose.bind( this )()
	}

	document.querySelector( "#menu_entries_wrapper" ).addEventListener( "mousemove", handleViewMouseMove );
	document.querySelector( "#menu_entries_wrapper" ).addEventListener( "click", handleItemClick.bind( this ) );
	document.querySelector( "#item_view" ).addEventListener( "click", handleItemClick.bind( this ) );

	document.querySelector( ".open" ).addEventListener( "mouseover", handleMouseOver.bind( this ) );
	document.querySelector( ".open" ).addEventListener( "mouseout", handleMouseOut.bind( this ) );
	document.querySelector( ".open" ).addEventListener( "click", handleOpen.bind( this ) );
	document.querySelector( ".close" ).addEventListener( "click", handleClose.bind( this ) );

	window.addEventListener( "resize", function(){
		entryObserver.disconnect();
		let menuObserverOptions = { threshold: getScreenSize()[0] >= 900 ? 0.05 : 0.7, rootMargin: getRootMargin() };
		
		entryObserver = new IntersectionObserver( handleIntersect, menuObserverOptions );
		document.querySelectorAll( ".menu-entry" ).forEach( function( entry ){
			entryObserver.observe( entry );
		} ) 
	} )

	window.addEventListener( "orientationchange", function(){
		entryObserver.disconnect();
		let menuObserverOptions = { threshold: getScreenSize()[0] >= 900 ? 0.05 : 0.7, rootMargin: getRootMargin() };
		
		entryObserver = new IntersectionObserver( handleIntersect, menuObserverOptions );
		document.querySelectorAll( ".menu-entry" ).forEach( function( entry ){
			entryObserver.observe( entry );
		} ) 
	} )

	return this
}
