export function EntryViewer( selector ){
	this.container = document.querySelector( selector );
	this.frame = this.container.querySelector( "iframe" );
	this.isViewing = false;
	this.selection;

	function handleBack(){
		this.close();
	}

	function turnOffViewing(){
		this.isViewing = false;
	}

	this.close = function(){
		this.container.classList.remove( "active" );
		this.frame.parentElement.classList.remove( "ready" );
		this.frame.removeAttribute( "src" );
		setTimeout( turnOffViewing.bind( this ), 500 );
		return this;
	}

	this.setSelection = function( entry ){
		this.isViewing = true;
		this.selection = entry;
		this.container.classList.add( "active" );
		this.frame.setAttribute( "src", entry.link + "?timestamp=" + Date.now() )
		return this;
	}

	this.frame.addEventListener( "load", function( evt ){
		evt.currentTarget.parentElement.classList.add( "ready" );
	})

	this.container.querySelector( ".close" )
		.addEventListener( "click", handleBack.bind( this ) )

	return this
}