export function on( evtName, listener ){
	if( typeof listener === "function" ){
		if( Object.keys(this.listeners).indexOf( evtName ) > -1 ){
			this.listeners[ evtName ].push( listener );
		} else {
			this.listeners[ evtName ] = [ listener ];
		}
	}
	return this;
}

export function off( evtName, listener ){
	if( typeof listener === "function" ){
		if( Object.keys( this.listeners ).indexOf( evtName ) > -1 ){
			var listenerIdx = this.listeners[ evtName ].findIndex( function( l ){
				return l.toString() === listener.toString()
			} )
			if( listenerIdx > -1 ){
				this.listeners[ evtName ].splice( idx, 1 ) ;
			}
		}	
	}
	return this;
}

export function dispatch( evtName ){
	var args = arguments.length > 1 ? Array.from( arguments ).slice( 1 ) : [];
	if( Object.keys( this.listeners ).indexOf( evtName ) > -1){
		this.listeners[evtName].forEach( function( listener ){
			listener.apply( listener, args );
		} )
	}
}