export const ENTRIES = [
	{
		order: 2,
		label: "Dubai",
		img: "dubai",
		imgURL: "covers/dubai.jpg",
		position: [1170, 1860],
		link: "https://showandtelldata.com"
	},
	{
		order: 3,
		label: "Kuala Lumpur",
		img: "kualalumpur",
		imgURL: "covers/kualalumpur.jpg",
		position: [ 650, 1720],
		link: "https://showandtelldata.com"
	},
	{	
		order: 4,
		label: "Hamburg",
		img: "hamburg",
		imgURL: "covers/hamburg.jpg",
		position: [2130, 780],
		link: "https://showandtelldata.com"
	},
	{
		order: 5,
		label: "Sydney",
		img: "sydney",
		imgURL: "covers/sydney.jpg",
		position: [ 2620, 600],
		link: "https://showandtelldata.com"
	},
	{
		order: 6,
		label: "Santorini",
		img: "santorini",
		imgURL: "covers/santorini.jpg",
		position: [1020, 740],
		link: "https://showandtelldata.com"
	},
	{
		order: 7,
		label: "Paris",
		img: "paris",
		imgURL: "covers/paris.jpg",
		position: [ 590, 570],
		link: "https://showandtelldata.com"
	},
	{
		order: 8,
		label: "New York",
		img: "newyork",
		imgURL: "covers/newyork.jpg",
		position: [3650, 1670],
		link: "https://showandtelldata.com"
	},
	{
		order: 11,
		label: "Hong Kong",
		img: "hongkong",
		imgURL: "covers/hongkong.jpg",
		position: [3525, 1050],
		link: "https://showandtelldata.com"
	},
	{
		order: 10,
		label: "Cologne",
		img: "cologne",
		imgURL: "covers/cologne.jpg",
		position: [4170, 760],
		link: "https://showandtelldata.com"
	},
	{
		order: 9,
		label: "Bogotá",
		img: "bogota",
		imgURL: "covers/bogota.jpg",
		position: [4360, 1400],
		link: "https://showandtelldata.com"
	},
	{
		order: 12,
		label: "Buenos Aires",
		img: "buenosaires",
		imgURL: "covers/buenosaires.jpg",
		position: [ 1215, 2620],
		link: "https://showandtelldata.com"
	},
	{
		order: 1,
		label: "Amsterdam",
		img: "amsterdam",
		imgURL: "covers/amsterdam.jpg",
		position: [ 1930, 2000 ],
		mobilePosition: [2300, 2250],
		link: "https://showandtelldata.com"
	},
	{
		order: 13,
		label: "Mexico City",
		img: "mexicocity",
		imgURL: "covers/mexicocity.jpg",
		position: [ 3310, 2620 ],
		mobilePosition: [ 2700, 2720 ],
		link: "https://showandtelldata.com"
	},
	{
		order: 14,
		label: "Jakarta",
		img: "jakarta",
		imgURL: "covers/jakarta.jpg",
		position: [ 680, 3620],
		link: "https://showandtelldata.com"
	},
	{
		order: 0,
		label: "Prague",
		img: "prague",
		imgURL: "covers/prague.jpg",
		position: [ 4220, 3020],
		link: "https://showandtelldata.com"
	},
	{
		order: 18,
		label: "Barcelona",
		img: "barcelona",
		imgURL: "covers/barcelona.jpg",
		position: [ 1920, 3060],
		link: "https://showandtelldata.com"
	},
	{
		order: 17,
		label: "Florence",
		img: "florence",
		imgURL: "covers/florence.jpg",
		position: [ 1180, 4330 ],
		link: "https://showandtelldata.com"
	},
	{
		order: 15,
		label: "Cairo",
		img: "cairo",
		imgURL: "covers/cairo.jpg",
		position: [ 2180, 4100],
		link: "https://showandtelldata.com"
	},
	{
		order: 19,
		label: "Istanbul",
		img: "istanbul",
		imgURL: "covers/istanbul.jpg",
		position: [ 3465, 3950],
		link: "https://showandtelldata.com"
	},
	{
		order: 20,
		label: "Cape Town",
		img: "capetown",
		imgURL: "covers/capetown.jpg",
		position: [ 4245, 4422 ],
		link: "https://showandtelldata.com"
	},
	{
		order: 16,
		label: "Delhi",
		img: "delhi",
		imgURL: "covers/delhi.jpg",
		position: [ 2920, 3380],
		link: "https://showandtelldata.com"
	},
];

export const STICKERS = [
	{
		img: 'sticker_d',
		position: [1190, 1240],
		link: "https://showandtelldata.com"
	},
	{
		img: 'sticker_c',
		position: [1700, 420],
		link: "https://showandtelldata.com"	
	},
	{
		img: 'sticker_a',
		position: [2670, 1400],
		link: "https://showandtelldata.com"
	},
	{
		img: 'sticker_b',
		position: [3900, 1260],
		link: "https://showandtelldata.com"
	},
	{
		img: 'sticker_d',
		position: [4550, 450],
		link: "https://showandtelldata.com"
	},
	{
		img: 'sticker_b',
		position: [490, 2670],
		link: "https://showandtelldata.com"
	},
	{
		img: 'sticker_a',
		position: [4320, 2358],
		link: "https://showandtelldata.com"
	},
	{
		img: 'sticker_a',
		position: [440, 4470],
		link: "https://showandtelldata.com"
	},
	{
		img: 'sticker_c',
		position: [1420, 3560],
		link: "https://showandtelldata.com"
	},
	{
		img: 'sticker_d',
		position: [2385, 3615],
		link: "https://showandtelldata.com"
	},
	{
		img: 'sticker_c',
		position: [2880, 4610],
		link: "https://showandtelldata.com"
	},
	{
		img: 'sticker_c',
		position: [3650, 3380],
		link: "https://showandtelldata.com"
	},
	{
		img: 'sticker_a',
		position: [4530, 3660],
		link: "https://showandtelldata.com"
	},
	{
		img: 'sticker_a',
		position: [2350, 2550],
		link: "https://showandtelldata.com"
	},
	{
		img: 'sticker_c',
		position: [2900, 1900],
		link: "https://showandtelldata.com"	
	},
	{
		img: 'sticker_b',
		position: [1800, 1500],
		link: "https://showandtelldata.com"
	}
]